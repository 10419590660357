import './Info.css';

const Info = () => {
    return (
        <div className='info-content'>
            <div className='about-title'>
                <svg className='about-icon' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
                </svg>
                <h1>What is Fukaverse Ai</h1>
            </div>
            <p>Fukaverse AI, a advanced conversational AI. Our cutting-edge model ensures the deepest, most engaging conversations, tailored to your preferences.</p>
            <p>
            With extensive customization options, you can create a unique AI companion with a personalized personality, interests, and conversational style. Each AI comes with a generative backstory, making interactions immersive and relatable.
            </p>
            <div className='community-title'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"/>
                </svg>
                <h1>Join Our Community</h1>
            </div>
            <p>Become part of the Fukaverse AI community and connect with other users to share experiences, tips, and feedback</p>
        </div>
    );
}
 
export default Info;