import React, { useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Info from './components/Info';
// import InputGroup from 'react-bootstrap/InputGroup';
import * as formik from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import './App.css';
import { Toaster, toast } from 'sonner';

function App() {
  const { Formik } = formik;

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email,setEmail] = useState('')
  const [name,setName] = useState('')
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const myFormRef = useRef(null);

  const serverPort = "6969";

  const schema = yup.object().shape({
    Name: yup.string().required(),
    Email: yup.string().email().required(),
  });

  const handleSignup = async (values) => {
    console.log('hit')
    
    if (email && name) {
      await axios.post(`https://fukaverse.com/api/signup`, {
      Name: name,
      Email: email,
    })
    .then(res => {
        // Handle the response here
        console.log(res.data);
        toast.success('Signup successful');
        setMessage('Signup successful');
        handleMessage();
      })
      .catch(err => {
        // Handle the error here
        if (err.response.status === 400) {
          console.error('Invalid email format');
          toast.error('Invalid email format');
          setErrorMessage('Invalid email format');
          handleErrorMessage();
        } else if (err.response.status === 401) {
          console.error('Email is already in use');
          toast.error('Email is already in use');
          setErrorMessage('Email is already in use');
          handleErrorMessage();
        } else {
          console.error(err);
        }
        // toast.error('Error signing up');
      });
    }

    setIsSubmitted(true);
  }
    
    const handleMessage = () => {
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 6000);
    }
    
    const handleErrorMessage = () => {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 6000);
    }

    const handleGoToForm = () => {
      const rect = myFormRef.current.getBoundingClientRect();
      window.scrollTo({ top: rect.top + window.pageYOffset - 250, behavior: 'smooth' });
    };

  return (
    <div className="App">
      <div className='bannerheader'>
      </div>
      <header className="App-header">
        <div className='app-nav'>
          <div className='nav-left'>
            <img src="https://assets.fukaverse.com/media/v2/LogoWhite.svg" alt="Logo" />
            <h1 className='nav-title'>Fukaverse Ai.</h1>
          </div>
          <div className='nav-right'>
            <Button variant="outline-light" onClick={() => {
              handleGoToForm()
            }}>Join Waitlist</Button>
          </div>
        </div>
        <div className='bannerCon'>
          <img src="./Banner.png" alt="" className='bannerImage'/>
        </div>
        <div className='content'>
          <Info/>
          <h1 style={{fontSize: '2rem'}}>Join our waitlist</h1>
          <p style={{fontSize: '1rem'}}>Join our waitlist to be the first to get access</p>
          <p style={{fontSize: '0.8rem'}}>Be notifed. 📧</p>
          <Toaster richColors  />
          {/* {
            showError && 
            <Alert className='alert' variant="danger" onClose={() => setShowError(false)} dismissible>
              <p className='alertMessage'>
                {errorMessage}
              </p>
            </Alert>
          }
          {
            showMessage && 
            <Alert className='alert' variant="success" onClose={() => setShowMessage(false)} dismissible>
              <p className='alertMessage'>
                {message}
              </p>
            </Alert>
          } */}
          <div className='form' id="form-section" ref={myFormRef}>
            <Formik
              validationSchema={schema}
              onSubmit={(values) => {
                handleSignup(values);
              }}
              initialValues={{
                Email: '',
                Name: '',
              }}
              >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group md="4" controlId="validationFormik01">
                        <Form.Label style={{fontSize:"0.8rem"}}>Enter Email</Form.Label>
                        <Form.Control
                          type="text"
                          name="Email"
                          value={email}
                          onChange={(e) => {
                            handleChange(e)
                            setEmail(e.target.value)
                          }}
                          isValid={touched.Email && !errors.Email}
                          isInvalid={!!errors.Email}
                          placeholder='Email'
                          />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group md="4" controlId="validationFormik01">
                        <Form.Label style={{fontSize:"0.8rem"}}>Enter Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="Name"
                          value={name}
                          onChange={(e) => {
                            handleChange(e)
                            setName(e.target.value)
                          }}
                          isValid={touched.Name && !errors.Name}
                          isInvalid={isSubmitted &&!!errors.Name}
                          placeholder='Name'
                          />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    <Button className='subbmitBtn' variant='info' type="submit">Join Waitlist</Button>
                  </Form>
              )}
            </Formik>
          </div>
          <div className="Image-header">
            <img src="https://assets.fukaverse.com/media/v2/LogoWhite.svg" alt="Logo" />
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
